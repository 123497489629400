<template>
  <div class="settings" v-if="user">
    <v-container class="pt-10">
      <v-row class="py-2 px-3 pt-1">
        <v-responsive class="overflow-visible">
          <h1 class="subheading grey--text">Impostazioni</h1>
        </v-responsive>
        <!-- <v-btn
              dark
              class="primary"
              @click.stop="goUpdate"
              v-if="goalsToUpdate && goalsToUpdate.length > 0" >
              Start Update
        </v-btn> -->
      </v-row>
      <v-row align="start" justify="start">
        <v-col cols="12" sm="12" md="12">
          <v-card class="pa-3 app_background" height="170">
            <v-card-title>
              <h2 class="subheading grey--text">Account</h2>
              <v-spacer></v-spacer>
              <v-btn @click="dialog = true" text small color="primary"
                >Modifica</v-btn
              >
            </v-card-title>
            <v-card-text>
              <v-row align="center" class="py-2" no-gutters>
                <v-col cols="2" sm="2" md="2">
                  <v-avatar color="grey" size="36">
                    <!-- <img v-if="user.avatar_url" :src="user.avatar_url" /> -->
                    <Avatar
                      v-if="user"
                      :userToAvatar="user"
                      :key="avatarComponentKey"
                    />
                  </v-avatar>
                </v-col>

                <v-col cols="10" sm="10" md="10">
                  <p class="body-1 mb-0">{{ user.name }}</p>
                  <p class="body-1 mb-0">{{ user.email }}</p>
                  <!-- <p class="body-1 mb-0">{{ user.id }}</p> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-0 pt-0">
        <v-btn class="mt-5 grey--text" text @click="logOut">Logout</v-btn>
      </v-row>
    </v-container>

    <!-- Dialog to edit user info -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Modifica Account</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Nome*"
                  v-model="user.name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Email*"
                  v-model="user.email"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp"
                  label="Modifica immagine di profilo"
                  hint="Seleziona e carica"
                  persistent-hint
                  prepend-icon="mdi-camera"
                  v-model="avatarImage"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
          <small>*campo obbligatorio</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Chiudi</v-btn>
          <v-btn color="primary" text @click="editUserProfile">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <OverlayLoading
      :overlaymessage="overlaymessage"
      :overlaymessage2="overlaymessage2"
      :inProgress="inProgress"
    />

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      color="success"
      top
      right
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
          >OK</v-btn
        >
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbar3"
      :timeout="snackbarTimeout3"
      color="success"
      top
      right
    >
      {{ snackbarText3 }}
      <template v-slot:action="{ attrs3 }">
        <v-btn color="white" text v-bind="attrs3" @click="snackbar3 = false"
          >OK</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  fb,
  usersCollection,
  storage,
  emailsCollection,
} from "@/plugins/firebase.js";
import Avatar from "@/components/Avatar.vue";
// import VImageInput from "vuetify-image-input";

export default {
  name: "Settings",
  title: "Settings",

  components: {
    Avatar,
    // VImageInput,
    OverlayLoading: () => import("@/components/OverlayLoading"),
  },

  data() {
    return {
      avatarImage: null,
      avatarComponentKey: 0,

      dialog: false,

      snackbar: false,
      snackbarText: "Avatar modificato con successo",
      snackbarTimeout: 5000,
      inProgress: false,
      overlaymessage: "Uploading in progress.",
      overlaymessage2: "",
      // uploadimagewidth: 256,

      // invite
      inviteEmail: null,

      // third snackbar
      snackbar3: false,
      snackbarText3: "Invite Sent!",
      snackbarTimeout3: 5000,

      // temp
      showInviteFuntionality: null,
    };
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },

  methods: {
    logOut() {
      this.$store.dispatch("logUserOut");
    },
    async editUserProfile() {
      // --------------------------------------------------------
      // Step 1 - Upload image file in storage (folder speakers/speaker_id/*)
      // --------------------------------------------------------

      if (this.avatarImage) {
        this.dialog = false;
        this.inProgress = true;

        const pathStorage = "gs://" + process.env.VUE_APP_PROJECT_ID + "/";
        const folderUpload = "users/";
        const pathFile = this.user.id + "/" + "avatar";

        const fileRef = fb
          .storage()
          .ref()
          .child(folderUpload + pathFile);
        await fileRef.put(this.avatarImage);
        this.GsUrlOriginal = pathStorage + folderUpload + pathFile;

        // --------------------------------------------------------
        // Step 2 - Update document in database to include image url
        // --------------------------------------------------------

        // wait some seconds to make sure thumbnail is created
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        await delay(4000); // wait x seconds

        // get the user public avatar from storage
        let pathAvatarImage =
          "gs://" +
          process.env.VUE_APP_STORAGE_BUCKET +
          "/" +
          "users/" +
          this.user.id +
          "/avatar_200x200";

        const urlImage = await storage
          .refFromURL(pathAvatarImage)
          .getDownloadURL()
          .catch((err) => console.log("errore in urlImg" + err));

        const userDataToAdd = {
          name: this.user.name,
          email: this.user.email,
          avatar_url: urlImage,
        };
        await usersCollection
          .doc(this.user.id)
          .set(userDataToAdd, { merge: true });
      } else {
        const userDataToAdd = {
          name: this.user.name,
          email: this.user.email,
        };
        await usersCollection
          .doc(this.user.id)
          .set(userDataToAdd, { merge: true });
      }

      // --------------------------------------------------------
      // Step 3 - Update vuex store, clear form and notify user
      // --------------------------------------------------------

      // update vuex - removed for now as not really useful
      // await this.$store.dispatch("fetchUserProfile");
      this.avatarComponentKey += 1;

      // then empty form
      this.avatarImage = null;

      // close dialog
      this.dialog = false;

      // Notify user the action was done with snackbar
      this.inProgress = false;
      this.snackbar = true;
    },
    inviteFriend() {
      emailsCollection
        .add({
          to: this.inviteEmail,
          template: {
            name: "invite",
            data: {
              name: this.user.name,
            },
          },
          // message: {
          //   subject: 'Unisciti a TS Together!',
          //   html: 'Sei stato invitato ad unirti a Techsoup Together! Usa questo codice per ottenere il 10% di sconto. 10%OFF',
          // },
          created_at: fb.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          console.log("Queued email for delivery!");
        });

      // GA4 event: user invites a friend
      this.$gtag.query("event", "invite_friend");

      // clear form
      this.inviteEmail = null;

      // snackbar
      this.snackbar3 = true;
    },
  },
};
</script>

<style></style>
