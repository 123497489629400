<template>
  <div class="avatar" v-if="userToAvatar">
    <v-avatar v-if="avatar_img" color="grey" size="36">
      <img v-if="avatar_img" :src="avatar_img" />
    </v-avatar>
    <v-avatar v-else color="grey" size="36">
      <!-- <v-icon dark>mdi-account-circle</v-icon> -->
      <img src="http://www.gravatar.com/avatar/?d=mp" />
    </v-avatar>
  </div>
</template>

<script>
import { storage } from "@/plugins/firebase.js";

export default {
  name: "Avatar",

  data() {
    return {
      avatar_img: null,
    };
  },

  props: {
    userToAvatar: {
      default: () => ({}),
    },
    userIdToAvatar: {
      default: () => ({}),
    },
  },

  methods: {},

  async created() {
    // get the user public avatar from storage
    let pathAvatarImage =
      "gs://" +
      process.env.VUE_APP_STORAGE_BUCKET +
      "/" +
      "users/" +
      this.userIdToAvatar +
      "/avatar_200x200";
    const urlImage = await storage
      .refFromURL(pathAvatarImage)
      .getDownloadURL()
      .catch(() => console.log("user does not have an avatar image"));

    this.avatar_img = urlImage;
  },
};
</script>

<style></style>
